<template>
  <div style="background: #fff">
    <headers />
    <div class="head">
      <img src="@/assets/images/forumBj.png" />
      <div class="headTit">常见问题</div>
      <div class="searchBox">
        <search @search="searchFun" :type="2" />
      </div>
    </div>
    <template v-if="list.length">
      <div class="content">
        <div class="card">
          <div class="cardItem" v-for="(item, index) in list" :key="index">
            <div class="title textOverflowOne">{{ item.title }}</div>

            <div class="titleDetails textOverflowTwo">
              {{ item.content }}
            </div>

            <div class="btnBox">
              <div></div>
              <el-button class="searchBoxR" @click="toDetails(item)"
                >查看资料</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pages.total"
          :page-size="pages.size"
          :current-page="pages.page"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </template>
    <None v-else />
    <footers />
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";

import { qaList } from "@/api/lx";

export default {
  name: "Procucts",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    return {
      searchValue: "",
      list: [],
      pages: {
        page: 1,
        total: 0,
        size: 15,
      },
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    currentChange(val) {
      this.pages.page = val;
      this.getList();
    },
    searchFun(val) {
      this.searchValue = val;
      this.pages.page = 1;
      this.getList();
    },
    getList() {
      qaList({
        page: this.pages.page,
        size: this.pages.size,
        title: this.searchValue,
      }).then((res) => {
        this.list = res.data.records;
        this.pages.total = res.data.total;
      });
    },
    toDetails(item) {
      this.$router.push({
        path: "/forum/details",
        query: { id: item.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  .headTit {
    width: 100%;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 42px;
    color: #ffffff;
    position: relative;
    z-index: 1;
    margin-top: 80px;
  }
  .searchBox {
    margin-top: 54px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .cardItem {
    width: 330px;
    margin-left: 45px;
    margin-top: 50px;
    border: 1px solid #e6edf0;
    background-color: #f9f9f9;
    border-top: 5px solid #00d8c3;
    padding: 24px 20px;
    box-sizing: border-box;
    .title {
      font-weight: 600;
      font-size: 18px;
      color: #00161a;
    }
    .titleDetails {
      font-weight: 400;
      font-size: 14px;
      color: #525f66;
      text-align: left;
      line-height: 22px;
      height: 44px;
      margin-top: 15px;
    }
    .btnBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 58px;
      .searchBoxR {
        width: 104px;
        height: 39px;
        background: #00d8c3;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
        &.is-active,
        &:active {
          border: 1px solid #00d8c3; /* 更改为你想要的颜色 */
          outline: none; /* 移除默认的轮廓线 */
        }
      }
    }
  }
}
.page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 65px;
}
</style>